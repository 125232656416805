import './App.css';
import React from 'react';
import 'react-horizontal-scrolling-menu/dist/styles.css';

import Background from './images/background.jpg'
import Pdf from './documents/Resume.pdf'
import Munchify from './images/Munchify.png'
import SportCred from './images/SportCred.png'
import Ninversion from './images/Ninversion.png'
import StudyBud from './images/StudyBud.png'
import Email from './images/Email.png'
import Linkedin from './images/Linkedin.png'
import Github from './images/Github.png'
import Codepxl from './images/Codepxl.jpg'
import Logo from './images/Logo.png'
import WideShot from './images/WideShot.jpg'
import ArrowLeft from './images/ArrowLeft.png'
import ArrowRight from './images/ArrowRight.png'
import ArrowLeftB from './images/ArrowLeftB.png'
import ArrowRightB from './images/ArrowRightB.png'
import Geotab from './images/Geotab.png'

import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';

function LeftArrow() {
  const { isFirstItemVisible, isLastItemVisible, scrollPrev } = React.useContext(VisibilityContext);

    const ArrowLeftSrc = isLastItemVisible ? ArrowLeft : ArrowLeftB;

  return (
    <div className='SidePanel'>
      <button className='PageBtnLeft' disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
        <img className='Pulsate' alt='Left Page' src={ArrowLeftSrc} style={{ height: '10vw', maxHeight: '100px' }}/>
      </button>
    </div>
  );
}

function RightArrow() {
  const { isFirstItemVisible, isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

  const ArrowRightSrc = isFirstItemVisible ? ArrowRight : ArrowRightB;

  return (
    <div className='SidePanel'>
      <button className='PageBtnRight' disabled={isLastItemVisible} onClick={() => scrollNext()}>
        <img className='Pulsate' alt='Right Page' src={ArrowRightSrc} style={{ height: '10vw', maxHeight: '100px' }}/>
      </button>
    </div>
  ); 
}

function App() {
  
  const handleClickScroll = (section) => {
    const element = document.getElementById(section);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="App">
      <header className='App-header'>
        <div className='Centered max-height'>
          <button 
            style={{border: 'none', backgroundColor: 'white'}}
            onClick={() => handleClickScroll("section-0")}
          >
            <img className='Title' src={Logo} alt='Sangwook Lee'/>
          </button>
          <div className="Menu">
            <button onClick={() => handleClickScroll("section-1")}>About</button>
            <button onClick={() => handleClickScroll("section-2")}>Experiences</button>
            <button onClick={() => handleClickScroll("section-3")}>Projects</button>
            <button onClick={() => handleClickScroll("section-4")}>Contacts</button>
            <a href={Pdf}>Resume</a>
          </div>
        </div>
      </header>
      <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
        <div id='section-0' className='App-banner' style={{ backgroundImage: `url(${Background})` }}>
          <div className='Section'>
            <div className='Heading'>
              <text>Hello!<span className='wave'>👋</span></text>
            </div>
            <div className='Subheading'>
               Welcome to my website.
            </div>
          </div>
        </div>
        <div id='section-1' className="About">
          <div className='Header'>
            ABOUT
          </div>
          <div className='Section'>
            <div className='Introduction'>
              <img src={WideShot} alt='Sangwook Lee' style={{ width: '50vw', maxWidth: '420px'}}></img>
              <div>
                <br/>&ensp;&ensp;&ensp;&ensp;Hello! I am Sangwook Lee,  a computer science student 
                at the University of Toronto with a love for web and mobile development.
                I have experiences with a wide range of tech stacks from React native mobile apps 
                to LAMP stack websites using PHP and MySQL.<br/><br/>
                &ensp;&ensp;&ensp;&ensp;I am hoping to further develop my skills as a full stack developer with a more deeper understanding of front end development as 
                I have a strong passion for making interactable and responsive interfaces that all users can enjoy.
              </div>
            </div>
          </div>
        </div>
        <div id='section-2' className="Experiences">
          <div className='Header'>
            EXPERIENCES
          </div>
          <div className='Section'>
            <div className='Centered'>
              <div className='List'>
              <div className='Experience'>
                  <img src={Geotab} alt='Geotab Inc.' style={{ width: '9%', border: '2px solid #708090', borderRadius: '10px'}}></img>
                  <div className='Descriptions'>
                    <div className='Desc_header'>
                      <div className='Desc_title'>
                        Software Developer - Intern
                      </div>
                      <div className='Desc_duration'>
                        Sep 2023 - Current
                      </div>
                    </div>
                    <div className='Desc_job'>
                      Geotab Inc.
                    </div>
                    <div className='Points'>
                      •  Reduced initial load time of a large data query by <strong>75%</strong> by implementing server-side pagination through 
                      Angular's event emitters and observers<br/>
                      • Streamlined api calls to return only strictly necessary values beck to the web, reducing response times 
                      by <b>78%</b> and resolving potential security vulnerabilities<br/>
                      • Optimized a frequently used SQL query by streamlining JOIN operations and implementing missing indexes, 
                      resulting in a <b>90%</b> increase in query execution speed<br/>
                    </div>
                  </div>
                </div>
                <div className='Experience'>
                  <img src={Codepxl} alt='Codepxl Inc.' style={{ width: '9%', border: '2px solid #708090', borderRadius: '10px'}}></img>
                  <div className='Descriptions'>
                    <div className='Desc_header'>
                      <div className='Desc_title'>
                        Full Stack Developer - Intern
                      </div>
                      <div className='Desc_duration'>
                        Sep 2022 - Dec 2022
                      </div>
                    </div>
                    <div className='Desc_job'>
                      Codepxl Inc.
                    </div>
                    <div className='Points'>
                      • Designed and developed data manipulation algorithms using MySQL and PHP, resulting in a <b>60x</b> improvement
                      in table management processes on a production database<br/>
                      • Built secure and efficient REST APIs for user authentication and access control using Laravel Passport,
                      meeting industry-standard <b>OAuth2.0</b> requirements<br/>
                      • Implemented secure HTTP session tokens in PHP to store user information across multiple requests and prevent
                      cross-site request forgery attacks<br/>
                    </div>
                  </div>
                </div>
                <div className='Experience'>
                  <img src={Codepxl} alt='Codepxl Inc.' style={{ width: '9%', border: '2px solid #708090', borderRadius: '10px'}}></img>
                  <div className='Descriptions'>
                    <div className='Desc_header'>
                      <div className='Desc_title'>
                        Mobile Developer - Intern
                      </div>
                      <div className='Desc_duration'>
                        June 2022 - Aug 2022
                      </div>
                    </div>
                    <div className='Desc_job'>
                      Codepxl Inc.
                    </div>
                    <div className='Points'>
                      • Successfully delivered a <b>React Native</b> mobile app through all stages of the development cycles, 
                      delivering the project <b>20%</b> ahead of the client's expected deadline<br/>
                      • Developed <b>over 70</b> frames of UI and core functionality using <b>Javascript</b> and <b>CSS</b>, 
                      delivering high-quality pages that met the client's requirements<br/>
                      • Analyzed user requirements and worked with designers and clients to create detailed entity-relationship 
                      diagrams (ERDs) for <b>over 40</b> tables, resulting in a comprehensive and efficient database structure
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id='section-3' className='Project'>
          <div className='Header'>
            PROJECTS
          </div>
          <div className='Section'>
            <div className='Grid'>
              <a href='https://devpost.com/software/munchify' className='Card'>
                <div className='Card_Image' style={{ backgroundImage: `url(${Munchify})` }}>
                  <div className='Desc_Title'>MUNCHIFY</div>
                  <div className='Desc_Info'>UofT Hacks hackathon project, a social media app to easily share recipes with friends.</div>
                </div>
              </a>
              <a href='https://docs.google.com/presentation/d/1UswiVKmQa7E09IKsLOksqPpJYcUIqXPNUnnOx5I2kpY/edit?usp=sharing' className='Card'>
                <div className='Card_Image' style={{ backgroundImage: `url(${SportCred})` }}>
                  <div className='Desc_Title'>SPORTCRED</div>
                  <div className='Desc_Info'>Course project with real clients to create a mobile app for sports pick-ems and discussions.</div>
                </div>
              </a>
              <a href='https://itch.io/jam/utsc-game-devs-game-jam-2020/rate/575581' className='Card'>
                <div className='Card_Image' style={{ backgroundImage: `url(${Ninversion})` }}>
                  <div className='Desc_Title'>NINVERSION</div>
                  <div className='Desc_Info'>UTSC GameJam 2020 submission, a 2D Puzzle Platformer with inverting cameras and smooth movement.</div>
                  <div className='Desc_Info'> Won 2nd place.</div>
                </div>
              </a>
              <a href='https://devpost.com/software/studybud' className='Card'>
                <div className='Card_Image' style={{ backgroundImage: `url(${StudyBud})`}}>
                  <div className='Desc_Title'>STUDYBUD</div>
                  <div className='Desc_Info'>Hack the North project, chrome extension to help with studying and essay writing.</div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div id='section-4' className='Contacts'>
          <div className='Header White'>
            CONTACTS
          </div>
          <div className='Section'>
            <div className='Contact'>
              <div className='Icon' style={{ backgroundImage: `url(${Email})`}}></div>
              leesangwook4517@gmail.com
            </div>
            <div className='Contact'>
              <div className='Icon' style={{ backgroundImage: `url(${Linkedin})`}}></div>
              https://www.linkedin.com/in/lee-sangwook/
            </div>
            <div className='Contact'>
              <div className='Icon' style={{ backgroundImage: `url(${Github})`}}></div>
              https://github.com/SSonagi
            </div>
          </div>
        </div>
      </ScrollMenu>
    </div>
  );
}

export default App;
